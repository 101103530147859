<template>
  <div id="speedbar" class="user-speedbar" :class="{'is-open': $store.state.menuIsOpen}">
    <!-- Audio menu -->
    <dropdown-menu v-if="hasRole('CORE_GUEST')">
      <template v-slot:title>
        <nals-client-status />
      </template>
      <template>
        <li v-if="!$store.state.nals.isConnected && !$store.state.nals.hasError">
          <a href="#" @click.prevent="onNalsStart">
            {{ $t('menu.nalsStart') }}
          </a>
        </li>
        <li v-if="$store.state.nals.isConnected || $store.state.nals.hasError">
          <a href="#" @click.prevent="onNalsStop">
            {{ $t('menu.nalsStop') }}
          </a>
        </li>
        <li v-if="$store.state.nals.isConnected">
          <a @click.prevent="openNalsSettingsDialog">
            {{ $t('menu.nalsConfigure') }}
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="onNalsLogs">
            {{ $t('menu.nalsLogs') }} <span class="badge" v-if="$store.state.nals.messages.length > 0">{{ $store.state.nals.messages.length }}</span>
          </a>
        </li>
        <li>
          <router-link :to="{ name: 'nals-download' }">
            {{ $t('menu.nalsDownload') }}
          </router-link>
        </li>
      </template>
    </dropdown-menu>

    <div class="menu-link">
      <router-link :to="{ name: 'rehearsal-sessions' }">
        <font-awesome-icon icon="fa-solid fa-microphone" size="lg" />
        {{ $t('menu.session') }}
      </router-link>
    </div>

    <div class="menu-link" v-if="isEligibleToSocial">
      <router-link :to="{ name: 'user-search' }">
        <font-awesome-icon icon="fa-solid fa-users" size="lg" />
        {{ $t('menu.community') }}
      </router-link>
    </div>

    <!-- Help menu -->
    <dropdown-menu>
      <template v-slot:title>
        <dropdown-menu-title>
          <font-awesome-icon icon="fa-solid fa-question-circle" size="lg" />
          {{ $t('menu.help') }}
        </dropdown-menu-title>
      </template>
      <template>
        <li>
          <a @click.prevent="openTuto" tabindex="0">
            {{ $t('menu.tutorial') }}
          </a>
        </li>
        <li>
          <a :href="landingBasedUrl('help')" target="_blank" rel="noopener">
            {{ $t('menu.online-help') }}
          </a>
        </li>
      </template>
    </dropdown-menu>

    <!-- User menu -->
    <menu-separator v-if="hasRole('CORE_GUEST')" />

    <div class="menu-link">
      <router-link :to="{ name: 'private-messages' }">
        <font-awesome-icon icon="fa-solid fa-envelope" size="lg" />
        {{ $t('menu.private-messages') }}
      </router-link>
    </div>

    <dropdown-menu :open-left="true" v-if="hasRole('CORE_GUEST')">
      <template v-slot:title>
        <dropdown-menu-title :aria-label="$t('menu.user')">
          <span class="speedbar-avatar">
            <user-avatar :profile-picture="currentUser.profilePicture"/>
          </span>
        </dropdown-menu-title>
      </template>
      <template>
        <li>
          <span class="speedbar-username">{{ currentUser.displayedUsername }}</span>
        </li>
        <li class="divider"></li>
        <li>
          <router-link :to="{ name: 'me' }">
            {{ $t('menu.profile') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'me-preferences' }">
            {{ $t('menu.preferences') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'me-subscription' }">
            {{ $t('menu.manage-offer') }}
          </router-link>
        </li>
        <li class="divider"></li>
        <li>
          <a @click.prevent="onLogout" tabindex="0">
            {{ $t('menu.sign-out') }}
          </a>
        </li>
      </template>
    </dropdown-menu>
  </div>
</template>

<script>
import {checkAndStartNals} from '../../lib/nals'
import NalsClientStatus from '../NalsClientStatus.vue'
import eventBus from '../../eventBus'
import NALS from '../../lib/nals-core'
import API from '../../api'
import UserAvatar from '../UserAvatar.vue'
import DropdownMenu from './DropdownMenu.vue'
import DropdownMenuTitle from './DropdownMenuTitle.vue'
import MenuSeparator from './MenuSeparator.vue'

export default {
  name: 'LayoutSpeedbar',
  components: {MenuSeparator, DropdownMenuTitle, DropdownMenu, UserAvatar, NalsClientStatus},
  props: {
    nalsError: String
  },
  mounted() {
    document.addEventListener('nalsl_not_installed', this.onNalslNotInstalled)
    document.addEventListener('nals_error_received', this.nalsErrorReceived)
  },
  beforeDestroy() {
    document.removeEventListener('nalsl_not_installed', this.onNalslNotInstalled)
    document.removeEventListener('nals_error_received', this.nalsErrorReceived)
  },
  computed: {
    currentUser: function () {
      return this.$store.state.user.currentUser
    }
  },
  methods: {
    hasRole: function (role) {
      if (this.currentUser === null) {
        return false
      }
      return this.currentUser.privileges.includes(role)
    },
    onNalsStart: function () {
      checkAndStartNals(this.$store.state.config.queueHost, this.currentUser.nalsToken)
    },
    onNalsStop: function () {
      NALS.stop()
    },
    onLogout: function () {
      NALS.stop()
      API.logout().then(() => {
        API.setAuth(null)
        this.$store.dispatch('user/logout')
        document.location = this.$store.state.config.landingUrl
      })
    },
    onNalsLogs: function () {
      eventBus.emit('open_nals_log')
    },
    openTuto: function () {
      eventBus.emit('show_dialog', {component: () => import('../../components/TutorialModal.vue'), size: 'tiny'})
    },
    onNalslNotInstalled: function () {
      eventBus.emit('show_dialog', {component: () => import('../../components/NalslNotInstalledModal.vue'), size: 'tiny'})
    },
    nalsErrorReceived: function (e) {
      if (e.detail.code === 'NALS_NOT_FOUND' && !this.$store.state.installLock) {
        eventBus.emit('show_dialog', {component: () => import('../../components/NalsPartialInstallationModal.vue'), size: 'tiny'})
      }
    },
    openNalsSettingsDialog: function () {
      eventBus.emit('show_dialog', {component: () => import('../../components/NalsSettingsDialog.vue'), size: 'small'})
    }
  }
}
</script>

<style lang="css" scoped>
.user-speedbar >>> svg {
  margin-right: 0.2em;
}

.speedbar-avatar {
  margin-right: 0.5em;
}

.menu-link {
  display: inline-block;
  margin-left: 1.4rem;
}

.speedbar-username {
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 0.7rem 1rem;
}

.speedbar-username:hover {
  cursor: default;
}

@media screen and (max-width: 39.9988em) {
  .user-speedbar {
    display: none;
  }

  .user-speedbar.is-open {
    display: block;
  }
}

.badge {
  background: #f0f0f0;
  border-radius: 10rem;
  color: #0a0a0a;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
