export function track(args) {
    window._paq.push(['trackEvent'].concat(args))
}

export function trackSearch(args) {
    window._paq.push(['trackSiteSearch'].concat(args))
}

export function trackPing() {
    window._paq.push(['ping'])
}

export function identify(userId) {
    window._paq.push(['setUserId', userId])
}

export function trackLogout() {
    window._paq.push(['resetUserId'])
}
