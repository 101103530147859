<template>
  <button class="sm-menu-toggle" @click.prevent="$emit('toggle-menu')">
    <img src="../../assets/img/menu-toggle.svg" fetchpriority="high" width="25" height="25" decoding="async" :alt="$t('menu-toggle.alt')" />
  </button>
</template>

<script>
export default {
  name: 'MenuToggleButton'
}
</script>

<style lang="css" scoped>
.sm-menu-toggle {
  display: none;
}

@media screen and (max-width: 39.9988em) {
  .sm-menu-toggle {
    background:transparent;
    border:1px solid hsla(0,0%,100%,.4);
    border-radius:.25rem;
    cursor: pointer;
    display: inline-block;
    padding: 15px .5rem;
  }

  .sm-menu-toggle>img {
    color: transparent;
  }
}
</style>
