import i18n from '../../i18n'
import {track, identify, trackLogout} from '../../lib/tracking'

const state = () => ({
    currentUser: null,
    blockedByList: []
})

const actions = {
    login(context, user) {
        context.commit('login', user)

        // Identify current user
        identify(user.id.toString())
    },
    logout(context) {
        context.commit('logout')

        trackLogout()
    },
    updateProfilePicture(context, profilePicture) {
        context.commit('updateProfilePicture', profilePicture)
        track(['profile', 'update_profile_picture'])
    },
    updateUsername(context, username) {
        context.commit('updateUsername', username)
        track(['profile', 'update_username'])
    },
    updateDisplayedUsername(context, username) {
        context.commit('updateDisplayedUsername', username)
        track(['profile', 'update_displayed_username'])
    },
    updateLocale(context, locale) {
        context.commit('updateLocale', locale)
        i18n.locale = locale
        track(['profile', 'update_locale'])
    },
    refreshBlockedByList(context, list) {
        context.commit('refreshBlockedByList', list)
    }
}

const mutations = {
    login(state, user) {
        state.currentUser = user
    },
    logout(state) {
        state.currentUser = null
        state.blockedByList = []
    },
    updateProfilePicture(state, profilePicture) {
        state.currentUser.profilePicture = profilePicture
    },
    updateUsername(state, username) {
        state.currentUser.username = username
        if (state.currentUser.displayedUsername === null) {
            state.currentUser.displayedUsername = username
        }
    },
    updateDisplayedUsername(state, displayedUsername) {
        if (displayedUsername === '') {
            state.currentUser.displayedUsername = state.currentUser.username
        } else {
            state.currentUser.displayedUsername = displayedUsername
        }
    },
    updateLocale(state, locale) {
        if (state.currentUser !== null) {
            state.currentUser.locale = locale
        }
    },
    refreshBlockedByList(state, list) {
        state.blockedByList = list
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}