export default {
    hideFooter(context) {
        context.commit('setHideFooter', true)
    },
    showFooter(context) {
        context.commit('setHideFooter', false)
    },
    saveConfig(context, config) {
        context.commit('saveConfig', config)
    }
}