import {track} from '../../../lib/tracking'

const state = () => ({
    sessionId: -1,
    type: '',
    runningSince: -1,
})

const getters = {
    isInSession(state) {
        return state.sessionId > -1
    }
}

const actions = {
    join(context, session) {
        context.commit('join', session)
        track(['rehearsal', 'join', session.sessionId])
    },
    quit(context, sessionId) {
        context.commit('quit')
        track(['rehearsal', 'quit', sessionId])
    }
}

const mutations = {
    join(state, session) {
        state.sessionId = session.sessionId
        state.type = session.type
    },
    quit(state) {
        state.sessionId = -1
        state.type = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}