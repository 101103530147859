import './lib/nals-core'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import API from './api'
import initLogs from './lib/logs'
import {checkDefaultLanguage} from './lib/i18n'
import PubSubPlugin from './plugin/pub-sub'
import VueMatomo from 'vue-matomo'
import './fontawesome'

Vue.config.productionTip = false

// Filters
Vue.filter('truncate', function (text, length, clamp) {
    clamp = clamp || '...'
    const node = document.createElement('div')
    node.innerHTML = text
    const content = node.textContent
    return content.length > length ? content.slice(0, length) + clamp : content
})

// Mixins
Vue.mixin({
    methods: {
        landingBasedUrl(url) {
            return this.$store.state.config.landingUrl + url
        }
    },
    computed: {
        // currentUser gets the current user object from the store.
        currentUser: function () {
            return this.$store.state.user.currentUser
        },
        isEligibleToSocial: function () {
            return this.currentUser !== null && this.currentUser.eligibleToSocial
        }
    }
})

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (el !== event.target && !el.contains(event.target)) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

function initApp(localConfig, versionInfos, currentUser, config) {
    initLogs(config.analytics.datadog)

    if (config.analytics.matomoID !== '') {
        Vue.use(VueMatomo, {
            host: config.analytics.matomoUrl,
            trackerUrl: config.analytics.matomoUrl + '/jones',
            siteId: config.analytics.matomoID,
            trackerFileName: 'jones',
            router: router,
            enableHeartBeatTimer: true,
        })
    } else {
        window._paq = []
    }

    if (localConfig['landing_url'] === undefined || localConfig['landing_url'] === '') {
        console.log('landing_url is invalid: ', localConfig['landing_url'])
        // Sensible default
        localConfig['landing_url'] = 'https://www.nucorder.fr/'
    }

    config.landingUrl = localConfig['landing_url']

    store.dispatch('saveConfig', config)
    localStorage.setItem('appVersion', versionInfos.version)

    let locale = checkDefaultLanguage()
    if (currentUser !== null) {
        locale = currentUser.locale
        store.dispatch('user/login', currentUser)
    }
    i18n.locale = locale

    // Install pub sub plugin
    Vue.use(PubSubPlugin, {'host': config.busHost})

    new Vue({
        router,
        i18n,
        store,
        render: h => h(App)
    }).$mount('#app')
}

(() => {
    function retry(localConfig) {
        // Get initial data
        Promise.all([
            API.appVersion(),
            API.me(),
            API.config()
        ]).then(([versionInfos, me, config]) => {
            initApp(localConfig, versionInfos, me, config)
        }).catch((res) => {
            console.log(res)
            setTimeout(retry, 2000)
        })
    }

    API.setApp('nuapp-frontend')
    fetch('/config.json')
        .then(res => res.json())
        .then(configJSON => {
            API.setBaseURL(configJSON['api_url'])
            retry(configJSON)
        })
        .catch(() => {
            API.setBaseURL(process.env.VUE_APP_API_URL)
            retry({
                'landing_url': process.env.VUE_APP_LANDING_URL
            })
        })
})()
