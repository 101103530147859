'use strict'

class PubSub {
    constructor (wsUrl) {
        this.url = wsUrl
        this.websocket = null
        this.isReady = false
        this.retryCount = 0
        this.subscribers = {}
        this.queue = []

        this.bindEvents()
    }

    on (topic, callback) {
        if (!(topic in this.subscribers)) {
            this.subscribers[topic] = []
            this.sendProtocolMessage('SUBSCRIBE', topic)
        }
        this.subscribers[topic].push(callback)
    }

    off (topic, callback) {
        if (!(topic in this.subscribers)) {
            return
        }
        let index = this.subscribers[topic].indexOf(callback)
        if (index > -1) {
            this.subscribers[topic].splice(index, 1)
        }
        if (this.subscribers[topic].length === 0) {
            this.sendProtocolMessage('UNSUBSCRIBE', topic)
        }
    }

    initWs () {
        if (!window.WebSocket) {
            console.log('unsupported websocket')
            return
        }
        let wsScheme = 'ws'
        if (location.protocol === 'https:') {
            wsScheme = 'wss'
        }

        this.unbindEvents()
        this.websocket = new WebSocket(wsScheme + '://' + this.url + '/ws')
        this.websocket.addEventListener('open', this.onReady.bind(this))
        this.websocket.addEventListener('error', this.onError.bind(this))
        this.websocket.addEventListener('close', this.onClose.bind(this))
        this.websocket.addEventListener('message', this.onMessage.bind(this))
    }

    closeWs () {
        this.unbindEvents()
        if (this.websocket !== null) {
            this.websocket.close()
        }
    }

    bindEvents () {
        window.addEventListener('beforeunload', () => {
            this.unbindEvents()
        })
    }

    unbindEvents () {
        if (this.websocket !== null) {
            this.websocket.removeEventListener('open', this.onReady)
            this.websocket.removeEventListener('error', this.onError)
            this.websocket.removeEventListener('close', this.onClose)
            this.websocket.removeEventListener('message', this.onMessage)
        }
    }

    onReady () {
        this.isReady = true
        this.retryCount = 0
        // Dequeue waiting messages
        while (this.queue.length > 0) {
            let waitingMessage = this.queue.shift()
            this.websocket.send(waitingMessage)
        }
    }

    onError () {
        this.isReady = false
        this.websocket.close()
    }

    onClose (evt) {
        if (evt.reason) {
            console.log('bus websocket closed: ', evt.reason)
        }
        this.isReady = false
        // Reconnect
        setTimeout(() => {
            this.retryCount++
            this.initWs()
        }, 10 ** this.retryCount)
    }

    onMessage (evt) {
        if (!evt.data) {
            return
        }
        let message = JSON.parse(evt.data)
        if (!(message.topic in this.subscribers)) {
            return
        }
        this.subscribers[message.topic].forEach(s => s(message))
    }

    sendProtocolMessage (action, channel, body) {
        let frame = action
        frame += ' '
        frame += channel
        frame += '\n'
        if (body) {
            frame += body
        }
        // Queue message until the websocket is ready
        if (!this.isReady) {
            this.queue.push(frame)
            return
        }
        this.websocket.send(frame)
    }

    sendMessage (channel, message) {
        this.sendProtocolMessage('PUBLISH', channel, JSON.stringify(message))
    }
}

export default PubSub
