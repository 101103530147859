<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>{{ modalTitle }}</h4>
      </div>
      <div class="modal-body">
          <slot />
      </div>
      <div class="modal-footer">
        <slot name="footer" />
      </div>
      <button class="close-button" aria-label="Close modal" type="button" @click.prevent="closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from '../eventBus'

export default {
  name: 'ModalDialog',
  props: {
    modalTitle: {
      type: String,
      default() {
        return ''
      }
    }
  },
  methods: {
    closeModal() {
      eventBus.emit('hide_dialog')
    }
  }
}
</script>

<style scoped lang="css">
.modal-header {
  text-align: center;
  padding: 0.8rem;
}

.modal-header > h4 {
  margin: 0;
}

.modal-body {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding: 1.5em;
  overflow-y: auto;
}

.close-button {
  color: #fff;
  top: 1rem;
  font-size: 1.6em;
}

.modal-footer {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding: 0.5em;
  text-align: center;
}
</style>