import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faArrowCircleLeft,
    faBan,
    faCamera,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faCircle,
    faSquare,
    faComments,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faLaptop,
    faLightbulb,
    faLink,
    faMicrophone,
    faMicrophoneSlash,
    faMobile,
    faMusic,
    faPen,
    faPlus,
    faPlusSquare,
    faQuestionCircle,
    faSearch,
    faTimes,
    faUsers,
    faGlobe,
    faLock,
    faWrench,
    faPaperPlane,
    faCogs,
    faSpinner,
    faCircleNotch,
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'

import {
    faWindowRestore
} from '@fortawesome/free-regular-svg-icons'

import {
    faWindows,
    faLinux,
    faApple,
} from '@fortawesome/free-brands-svg-icons'

// Register FontAwesome icon component
library.add(
    faEnvelope,
    faQuestionCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faUsers,
    faMicrophone,
    faMicrophoneSlash,
    faCaretDown,
    faMobile,
    faLaptop,
    faPlusSquare,
    faSearch,
    faComments,
    faBan,
    faCheckCircle,
    faLightbulb,
    faArrowCircleLeft,
    faTimes,
    faPlus,
    faLink,
    faCheck,
    faCircle,
    faSquare,
    faMusic,
    faCamera,
    faPen,
    faGlobe,
    faLock,
    faWrench,
    faPaperPlane,
    faCogs,
    faSpinner,
    faWindowRestore,
    faCircleNotch,
    faEye,
    faEyeSlash,
    faWindows,
    faLinux,
    faApple,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)