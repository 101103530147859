import store from '../store'
import API from '../api'
import semver from 'semver'

let appVersionSet = false

export const updateMiddleware = (to) => new Promise((resolve) => {
    const currentAppVersion = localStorage.getItem('appVersion') || '0.0.0'
    API.appVersion().then(versionPackage => {
        if (!appVersionSet) {
            localStorage.setItem('appVersion', versionPackage.version)
            appVersionSet = true
        }
        if (semver.gt(versionPackage.version, currentAppVersion)) {
            console.log('update app to version', versionPackage.version)
            window.location.href = to.fullPath
            return
        }
        resolve()
    })
})

/**
 * appStartMiddleware shows an app start on the first route shown when the user is logged in, if possible.
 * @param to
 * @param from
 * @param next
 * @returns {Promise}
 */
export const appStartMiddleware = (to, from, next) => new Promise((resolve) => {
    if (store.state.user.currentUser !== null && !store.state.appStartViewed) {
        API.appstart().then(appStart => {
            store.commit('showAppStart')
            if (appStart !== '') {
                if (appStart === 'nals-download-app-start') {
                    next({name: 'onboarding'})
                } else {
                    next({name: 'app-start', query: {t: appStart}})
                }
            }
        })
    }
    resolve()
})

/**
 * checkPrivilegesMiddleware ensures the user is allowed to see the requested page.
 * @param to
 * @param from
 * @param next
 * @returns {Promise}
 */
export const checkPrivilegesMiddleware = (to, from, next) => new Promise((resolve) => {
    if (store.state.user.currentUser !== null && to.meta.guestRequired) {
        next({name: 'hall'})
    } else if (to.meta.roles !== undefined && to.meta.roles.length > 0) {
        if (store.state.user.currentUser === null) {
            localStorage.setItem('redirectURL', to.fullPath)
            next({name: 'login'})
            resolve()
            return
        }
        if (store.state.user.currentUser.privileges.some(privilege => to.meta.roles.includes(privilege))) {
            next()
        } else {
            next({name: 'not-found'})
        }
    } else {
        next()
    }
    resolve()
})
