<template>
  <span class="separator" ></span>
</template>

<script>
export default {
  name: "MenuSeparator",
}
</script>

<style scoped>
.separator {
  margin-left: 1.4rem;
}

.separator:before {
  opacity: 0.4;
  content: "\00007C";
}
</style>