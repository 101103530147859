export const dateTimeFormats = {
  fr: {
    month: {
      month: 'short',
      year: 'numeric'
    },
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    long: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    time: {
        hour: 'numeric',
        minute: 'numeric'
    }
  },
  en: {
    month: {
      month: 'short',
      year: 'numeric'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}
