<template>
  <div :class="'callout text-center '+currentClass" data-closable
       v-if="this.$store.state.config.nalsRefVersion !== this.$store.state.nals.version">
    <div v-if="!nalsUpdateInProgress" style="margin-top: -1rem; margin-bottom: -1rem;">
      {{ $t('nals-upgrade-available.upgrade-available', [this.$store.state.config.nalsRefVersion]) }}
      <button class="button primary" style="margin-top: 0.9em;" @click.prevent="onNALSUpdate">{{
          $t('nals-upgrade-available.upgrade-btn')
        }}
      </button>
    </div>
    <div v-else-if="nalsError !== null">
      <p>{{ $t('nals-upgrade-available.an-error-occurred', [nalsError['code']]) }}</p>
    </div>
    <div v-else>
      <p>{{ $t('nals-upgrade-available.upgrade-in-progress-btn') }} <font-awesome-icon icon="fa-solid fa-circle-notch" :spin="true" /></p>
    </div>
    <button class="close-button" aria-label="Dismiss" type="button" data-close>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import NALS from '../lib/nals-core'

export default {
  name: 'NalsUpgradeAvailable',
  data: function () {
    return {
      nalsUpdateInProgress: false,
      nalsUpgradeInProgress: false,
      nalsError: null,
      timer: null,
      currentClass: 'success'
    }
  },
  watch: {
    '$store.state.nals.isConnected': {
      immediate: true,
      handler: function (isConnected) {
        if (isConnected) {
          this.currentClass = 'success'
          this.nalsError = null
          this.nalsUpdateInProgress = false
        }
      }
    }
  },
  mounted() {
    document.addEventListener('nals_upgrade_ready_received', this.onNALSUpgradeReady)
    document.addEventListener('nals_error_received', this.onNALSErrorReceived)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    document.removeEventListener('nals_upgrade_ready_received', this.onNALSUpgradeReady)
    document.removeEventListener('nals_error_received', this.onNALSErrorReceived)
  },
  methods: {
    onNALSUpdate: function () {
      this.nalsUpdateInProgress = true
      NALS.downloadNals(this.$store.state.config.nalsRefVersion)
    },
    onNALSUpgradeReady: function () {
      if (this.nalsUpgradeInProgress) {
        return
      }
      this.nalsUpgradeInProgress = true
      NALS.upgradeNals()
    },
    onNALSErrorReceived: function (e) {
      if (e.detail.code !== 'NALS_NOT_STARTED') {
        this.nalsError = e.detail
        this.nalsUpdateInProgress = false
        this.currentClass = 'alert'
      }
    }
  }
}
</script>
