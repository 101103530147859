<template>
  <div id="speedbar" class="guest-speedbar" :class="{'is-open': $store.state.menuIsOpen}">
    <ul class="menu">
      <li>
        <router-link :to="{ name: 'signup' }">
          {{ $t('layout-guest-speedbar.create-account') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'login' }">
          {{ $t('layout-guest-speedbar.login') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LayoutGuestSpeedbar'
}
</script>

<style lang="css" scoped>
@media screen and (max-width: 39.9988em) {
  .guest-speedbar {
    display: none;
  }

  .guest-speedbar.is-open {
    display: block;
  }
}

.guest-speedbar .menu {
  background-color: #010415;
  color: #eee;
}
</style>
