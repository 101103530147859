<template>
  <modal-dialog :modal-title="$t('nals-debug-console.nals-log')">
    <template>
      <ul class="messages-list">
        <li class="text-center" v-if="$store.state.nals.messages.length === 0">
          {{ $t('nals-debug-console.no-nals-error') }}
        </li>
        <li v-else v-for="(message, index) in $store.state.nals.messages" v-bind:key="index">
          <span>
            <font-awesome-icon icon="fa-solid fa-check" v-if="!message.isError" />
            <font-awesome-icon icon="fa-solid fa-times" v-else />
          </span>
          &nbsp;
          <span>{{ $d(message.timestamp, 'long') }} : <strong>{{ message.code }}</strong></span>
        </li>
      </ul>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'

export default {
  name: 'NalsDebugConsole',
  components: {
    ModalDialog,
  }
}
</script>

<style scoped lang="css">
.messages-list {
  list-style: none;
}
</style>