<template>
  <dropdown-menu-title>
    <font-awesome-icon class="client-status" :class="statusClass" icon="fa-solid fa-music" size="lg" />
    {{ $t('nals-client-status.audioConfig') }}
  </dropdown-menu-title>
</template>

<script>
import DropdownMenuTitle from './layout/DropdownMenuTitle.vue'

export default {
  name: 'NalsClientStatus',
  components: {DropdownMenuTitle},
  computed: {
    statusClass: function () {
      if (this.$store.state.nals.hasError) {
        return 'error'
      } else if (this.$store.state.nals.isConnected) {
        return 'connected'
      } else {
        return 'disconnected'
      }
    }
  }
}
</script>

<style lang="css" scoped>
.client-status {
  color: #da5412;
}

.client-status.connected {
  color: #5da423;
}

.client-status.disconnected {
  color: #a7aeae;
}

.client-status.error {
  color: orange;
}
</style>
