<template>
  <div class="logo">
    <router-link :to="{ name: currentUser !== null ? 'hall' : 'index' }">
      <picture>
        <source type="image/webp" srcset="../../assets/img/logo2.webp" />
        <img fetchpriority="high" src="../../assets/img/logo2.png" alt="Nucorder logo" />
      </picture>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'LayoutHeaderLogo'
}
</script>

<style lang="css" scoped>
.logo {
  margin-right: 1em;
}

.logo img {
  height: 45px;
}

.logo a {
  border: none;
}
</style>
