<template>
  <picture v-if="profilePicture !== null">
    <img :src="profilePicture" :class="sizeClass" alt="avatar" loading="lazy" />
  </picture>
  <picture v-else>
    <source type="image/webp" srcset="../assets/img/profile/default-avatar.webp" />
    <img :class="sizeClass" src="../assets/img/profile/default-avatar.png" alt="avatar" loading="lazy" />
  </picture>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    profilePicture: String,
    size: String
  },
  computed: {
    sizeClass: function () {
      switch (this.size) {
        case 'md':
          return 'avatar-md'
        case 'lg':
          return 'avatar-lg'
        default:
          return 'avatar'
      }
    }
  }
}
</script>
