<template>
  <p class="text-center">
    <font-awesome-icon icon="fa-solid fa-spinner" size="2x" :spin="true" />
  </p>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>
