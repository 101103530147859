<template>
  <footer class="global-footer" :class="{'show-for-medium': $store.state.hideFooter}">
    <div>
      <p>
        <a href="#" class="footer-link" @click.prevent="openTuto">{{ $t('app.tutorial') }}</a>
        <a :href="landingBasedUrl('help')" class="footer-link" target="_blank" rel="noopener">
          {{ $t('app.online-help') }}
        </a>
        <a :href="landingBasedUrl('legal-mentions')" class="footer-link" target="_blank" rel="noopener">
          {{ $t('app.legal-mentions') }}
        </a>
        <a :href="landingBasedUrl('cgu')" class="footer-link" target="_blank" rel="noopener">
          {{ $t('app.cgu') }}
        </a>
      </p>
      <p>
        <span>
            {{ $t('app.copyright', [new Date().getFullYear()]) }}
        </span>
        -
        {{ $t('app.status') }} <server-status-indicator :is-healthy="isHealthy" />
      </p>
    </div>
  </footer>
</template>

<script>
import API from '../../api'
import eventBus from '../../eventBus'
import TutorialModal from '../../components/TutorialModal.vue'
import ServerStatusIndicator from '../ServerStatusIndicator.vue'

export default {
  name: 'LayoutFooter',
  components: {ServerStatusIndicator},
  data: function () {
    return {
      interval: null,
      isHealthy: true
    }
  },
  mounted() {
    this.checkHealth()
    this.interval = window.setInterval(this.checkHealth, 1000 * 60 * 5)
  },
  beforeDestroy() {
    window.clearInterval(this.interval)
  },
  methods: {
    checkHealth: function () {
      API.health().then((res) => {
        this.isHealthy = res === 'ok'
      }).catch(() => {
        this.isHealthy = false
      })
    },
    openTuto: function () {
      eventBus.emit('show_dialog', {component: TutorialModal, size: 'tiny'})
    }
  }
}
</script>

<style lang="css" scoped>
.global-footer {
  background: #010415;
  border-top: solid 2px #f26f32;
  padding: 0.6rem 0.9rem;
}

.global-footer  p {
  text-align: center;
  font-size: 12px;
  color: #dddddd;
  margin-bottom: 0.9rem;
  margin-top: 0.9rem;
}

.footer-link {
  color: #fff;
  font-weight: bold;
  font-size: .9rem;
  line-height: 2rem;
  padding: .85em 1em;
}

.footer-link:hover {
  text-decoration: underline;
}

</style>
