export const numberFormats = {
    'fr': {
        shortCurrency: {
            style: 'currency'
        },
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    },
    'en': {
        shortCurrency: {
            style: 'currency'
        },
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    }
}
