import { render, staticRenderFns } from "./DropdownMenuTitle.vue?vue&type=template&id=066a3878&scoped=true"
import script from "./DropdownMenuTitle.vue?vue&type=script&lang=js"
export * from "./DropdownMenuTitle.vue?vue&type=script&lang=js"
import style0 from "./DropdownMenuTitle.vue?vue&type=style&index=0&id=066a3878&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066a3878",
  null
  
)

export default component.exports