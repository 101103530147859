<template>
  <span :class="{'status-ok': isHealthy, 'status-ko': !isHealthy}"><font-awesome-icon icon="fa-solid fa-circle" /></span>
</template>

<script>
export default {
  name: "ServerStatusIndicator",
  props: {
    isHealthy: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped>
.status-ok {
  color: #5da423;
}

.status-ko {
  color: red;
}
</style>