const state = () => ({
    unreadPrivateMessages: 0
})

const actions = {
    updateUnreadPrivateMessages(context, counter) {
        context.commit('updateUnreadPrivateMessages', counter)
    }
}

export const mutations = {
    updateUnreadPrivateMessages(state, counter) {
        state.unreadPrivateMessages = counter
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}