import NALS from '../../lib/nals-core'
import {track} from '../../lib/tracking'
import semver from 'semver'

const state = () => ({
    isConnected: false,
    hasAudioDriverError: false,
    hasError: false,
    name: '',
    host: '',
    hostname: '',
    version: '',
    messages: [],
    currentConfig: {
        jitterToggle: true,
        jitterConfig: {
            local: 0,
            server: 0
        },
        audioQuality: 1,
        audioChannel: 0,
        reverbSide: 0,
        reverbValue: 0,
        audioBufferSize: 0,
        supportedAudioBufferSizes: [],
        soundCard: ''
    },
    effectsConfig: {
        amp: {
            enabled: false,
            availableAmpsList: [],
            bounds: {
                bass: {
                    min: 0,
                    max: 0
                },
                middle: {
                    min: 0,
                    max: 0
                },
                treble: {
                    min: 0,
                    max: 0
                },
                drive: {
                    min: 0,
                    max: 30
                },
                gain: {
                    min: 0,
                    max: 0
                }
            },
            selectedStack: 0,
            bass: 0,
            middle: 0,
            treble: 0,
            drive: 0,
            gain: 0,
            insane: false
        },
        delay: {
            enabled: false,
            bounds: {
                timeInSec: {
                    min: 0,
                    max: 0
                },
                feedback: {
                    min: 0,
                    max: 0
                },
                mixer: {
                    min: 0,
                    max: 0
                }
            },
            timeInSec: 0,
            feedback: 0,
            mixer: 0,
            chorus: false
        },
        chorus: {
            enabled: false,
            bounds: {
                pitchInSec: {
                    min: 0,
                    max: 0
                },
                voices: {
                    min: 0,
                    max: 0
                },
                rateInHz: {
                    min: 0,
                    max: 0
                },
                depth: {
                    min: 0,
                    max: 0
                },
                width: {
                    min: 0,
                    max: 0
                },
                mixer: {
                    min: 0,
                    max: 0
                }
            },
            pitchInSec: 0,
            voices: 0,
            rateInHz: 0,
            depth: 0,
            width: 0,
            mixer: 0
        }
    }
})

function ensureBounds(value, bounds) {
    if (value < bounds.min) {
        value = bounds.min
    } else if (value > bounds.max) {
        value = bounds.max
    }
    return value
}

const actions = {
    connect(context, helloData) {
        context.commit('connect', helloData)
        console.log('NALS started')
        track(['nals', 'connected'])
    },
    disconnect(context) {
        context.commit('disconnect')
        track(['nals', 'disconnected'])
    },
    saveConfig(context, config) {
        context.commit('saveConfig', config)
    },
    saveEffectsConfig(context, config) {
        context.commit('saveEffectsConfig', config)
    },
    message(context, message) {
        context.commit('message', message)
    },
    clearMessages(context) {
        context.commit('clearMessages')
    },
    toggleAutoJitter(context, value) {
        context.commit('toggleAutoJitter', value)
        NALS.toggleAutoJitter()
        if (!value) {
            NALS.getCurrentConfig()
        }
    },
    setLocalJitter(context, value) {
        context.commit('setLocalJitter', value)
    },
    setServerJitter(context, value) {
        context.commit('setServerJitter', value)
    },
    setSoundCard(context, value) {
        context.commit('setSoundCard', value)
        NALS.setSoundCard(value)
    },
    setAudioChannel(context, value) {
        context.commit('setAudioChannel', value)
        NALS.setAudioChannel(value)
    },
    setAudioQuality(context, value) {
        context.commit('setAudioQuality', value)
        NALS.setAudioQuality(value)
    },
    setReverb(context, value) {
        context.commit('setReverb', value)
        NALS.setReverb(value)
    },
    toggleAmpEffect(context) {
        if (context.state.effectsConfig.amp.enabled) {
            context.commit('setAmpEffect', false)
            NALS.disableZtube()
        } else {
            context.commit('setAmpEffect', true)
            NALS.enableZtube()
        }
    },
    setAmpSelectedStack(context, value) {
        const ampListLen = context.state.effectsConfig.amp.availableAmpsList.length
        if (value < 0) {
            value = 0
        } else if (value >= ampListLen) {
            value = ampListLen - 1
        }
        context.commit('setAmpSelectedStack', value)
        NALS.setZtubeStack(value)
    },
    setAmpInsane(context, value) {
        context.commit('setAmpInsane', value)
        NALS.setZtubeInsane(value)
    },
    setAmpBass(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.amp.bounds.bass)
        context.commit('setAmpBass', value)
        NALS.setZtubeBass(value)
    },
    setAmpMiddle(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.amp.bounds.middle)
        context.commit('setAmpMiddle', value)
        NALS.setZtubeMiddle(value)
    },
    setAmpTreble(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.amp.bounds.treble)
        context.commit('setAmpTreble', value)
        NALS.setZtubeTreble(value)
    },
    setAmpDrive(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.amp.bounds.drive)
        context.commit('setAmpDrive', value)
        NALS.setZtubeDrive(value)
    },
    setAmpGain(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.amp.bounds.gain)
        context.commit('setAmpGain', value)
        NALS.setZtubeGain(value)
    },
    toggleDelayEffect(context) {
        if (context.state.effectsConfig.delay.enabled) {
            context.commit('setDelayEffect', false)
            NALS.disableDelay()
        } else {
            context.commit('setDelayEffect', true)
            NALS.enableDelay()
        }
    },
    setDelayTimeInSec(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.delay.bounds.timeInSec)
        context.commit('setDelayTimeInSec', value)
        NALS.setDelayTimeInSec(value)
    },
    setDelayFeedback(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.delay.bounds.feedback)
        context.commit('setDelayFeedback', value)
        NALS.setDelayFeedback(value)
    },
    setDelayMixer(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.delay.bounds.mixer)
        context.commit('setDelayMixer', value)
        NALS.setDelayMixer(value)
    },
    setDelayChorus(context, value) {
        context.commit('setDelayChorus', value)
        NALS.setDelayChorus(value)
    },
    toggleChorusEffect(context) {
        if (context.state.effectsConfig.chorus.enabled) {
            context.commit('setChorusEffect', false)
            NALS.disableChorus()
        } else {
            context.commit('setChorusEffect', true)
            NALS.enableChorus()
        }
    },
    setChorusPitch(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.pitchInSec)
        context.commit('setChorusPitch', value)
        NALS.setChorusPitch(value)
    },
    setChorusVoices(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.voices)
        context.commit('setChorusVoices', value)
        NALS.setChorusVoices(value)
    },
    setChorusRate(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.rateInHz)
        context.commit('setChorusRate', value)
        NALS.setChorusRate(value)
    },
    setChorusDepth(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.depth)
        context.commit('setChorusDepth', value)
        NALS.setChorusDepth(value)
    },
    setChorusWidth(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.width)
        context.commit('setChorusWidth', value)
        NALS.setChorusWidth(value)
    },
    setChorusMixer(context, value) {
        value = ensureBounds(value, context.state.effectsConfig.chorus.bounds.mixer)
        context.commit('setChorusMixer', value)
        NALS.setChorusMixer(value)
    },
    setAudioBufferSize(context, value) {
        context.commit('setAudioBufferSize', value)
        NALS.setPrefAudioBufferSize(value)
    }
}

export const mutations = {
    connect(state, helloData) {
        state.isConnected = true
        state.hasAudioDriverError = false
        state.hasError = false
        state.name = helloData.name
        state.host = helloData.host
        state.hostname = helloData.hostname
        state.version = helloData.nals_version
    },
    disconnect(state) {
        state.isConnected = false
        state.hasError = false
        state.name = ''
        state.host = ''
        state.hostname = ''
        state.version = ''
        state.currentConfig = {
            jitterToggle: true,
            jitterConfig: {
                local: 0,
                server: 0
            },
            audioQuality: 1,
            audioChannel: 0,
            reverbSide: 0,
            reverbValue: 0,
            audioBufferSize: 0,
            supportedAudioBufferSizes: [],
            soundCard: ''
        }
    },
    saveConfig(state, nalsConfig) {
        if (Object.prototype.hasOwnProperty.call(nalsConfig, 'jitterToggle')) {
            state.currentConfig.jitterToggle = nalsConfig.jitterToggle
        } else {
            state.currentConfig.jitterToggle = true
        }
        if (Object.prototype.hasOwnProperty.call(nalsConfig, 'jitterConfig')) {
            state.currentConfig.jitterConfig.local = nalsConfig.jitterConfig.local
            state.currentConfig.jitterConfig.server = nalsConfig.jitterConfig.server
        } else {
            state.currentConfig.jitterConfig.local = 0
            state.currentConfig.jitterConfig.server = 0
        }
        state.currentConfig.audioQuality = nalsConfig.audioQuality
        state.currentConfig.audioChannel = nalsConfig.audioChannel
        state.currentConfig.reverbValue = nalsConfig.reverb
        if (Object.prototype.hasOwnProperty.call(nalsConfig, 'reverbSide')) {
            state.currentConfig.reverbSide = nalsConfig.reverbSide
        }
        state.currentConfig.soundCard = nalsConfig.soundCard

        if (Object.prototype.hasOwnProperty.call(nalsConfig, 'actual_buffer_size')) {
            state.currentConfig.audioBufferSize = nalsConfig.actual_buffer_size
        }

        if (Object.prototype.hasOwnProperty.call(nalsConfig, 'supported_buffer_sizes')) {
            state.currentConfig.supportedAudioBufferSizes = nalsConfig.supported_buffer_sizes
        }
    },
    saveEffectsConfig(state, effectsConfig) {
        // delay
        state.effectsConfig.delay.enabled = effectsConfig['nc_delay_state']
        state.effectsConfig.delay.timeInSec = effectsConfig['nc_delay_timeInSec']
        state.effectsConfig.delay.feedback = effectsConfig['nc_delay_feedback']
        state.effectsConfig.delay.mixer = effectsConfig['nc_delay_mixer']
        state.effectsConfig.delay.chorus = effectsConfig['nc_delay_chorus_on']

        // delay bounds
        state.effectsConfig.delay.bounds.mixer.min = effectsConfig['nc_delay_mixer_min']
        state.effectsConfig.delay.bounds.mixer.max = effectsConfig['nc_delay_mixer_max']
        state.effectsConfig.delay.bounds.timeInSec.min = effectsConfig['nc_delay_timeInSec_min']
        state.effectsConfig.delay.bounds.timeInSec.max = effectsConfig['nc_delay_timeInSec_max']
        state.effectsConfig.delay.bounds.feedback.min = effectsConfig['nc_delay_feedback_min']
        state.effectsConfig.delay.bounds.feedback.max = effectsConfig['nc_delay_feedback_max']

        // ztube
        state.effectsConfig.amp.enabled = effectsConfig['tube_stack_state']
        state.effectsConfig.amp.availableAmpsList = effectsConfig['tube_stack_list']
        state.effectsConfig.amp.gain = effectsConfig['tube_stack_gain']
        state.effectsConfig.amp.drive = effectsConfig['tube_stack_drive']
        state.effectsConfig.amp.bass = effectsConfig['tube_stack_bass']
        state.effectsConfig.amp.middle = effectsConfig['tube_stack_middle']
        state.effectsConfig.amp.treble = effectsConfig['tube_stack_treble']
        state.effectsConfig.amp.insane = effectsConfig['tube_stack_insane']
        state.effectsConfig.amp.selectedStack = effectsConfig['tube_stack_index']

        // ztube bounds
        state.effectsConfig.amp.bounds.bass.min = effectsConfig['tube_stack_bass_min']
        state.effectsConfig.amp.bounds.bass.max = effectsConfig['tube_stack_bass_max']
        state.effectsConfig.amp.bounds.middle.min = effectsConfig['tube_stack_middle_min']
        state.effectsConfig.amp.bounds.middle.max = effectsConfig['tube_stack_middle_max']
        state.effectsConfig.amp.bounds.treble.min = effectsConfig['tube_stack_treble_min']
        state.effectsConfig.amp.bounds.treble.max = effectsConfig['tube_stack_treble_max']
        state.effectsConfig.amp.bounds.gain.min = effectsConfig['tube_stack_gain_min']
        state.effectsConfig.amp.bounds.gain.max = effectsConfig['tube_stack_gain_max']

        if (Object.prototype.hasOwnProperty.call(effectsConfig, 'nc_chorus_state')) {
            // chorus
            state.effectsConfig.chorus.enabled = effectsConfig['nc_chorus_state']
            state.effectsConfig.chorus.pitchInSec = effectsConfig['nc_chorus_pitchInSec']
            state.effectsConfig.chorus.voices = effectsConfig['nc_chorus_voices']
            state.effectsConfig.chorus.rateInHz = effectsConfig['nc_chorus_rateInHz']
            state.effectsConfig.chorus.depth = effectsConfig['nc_chorus_depth']
            state.effectsConfig.chorus.width = effectsConfig['nc_chorus_width']
            state.effectsConfig.chorus.mixer = effectsConfig['nc_chorus_mixer']

            // chorus bounds
            state.effectsConfig.chorus.bounds.pitchInSec.min = effectsConfig['nc_chorus_pitchInSec_min']
            state.effectsConfig.chorus.bounds.pitchInSec.max = effectsConfig['nc_chorus_pitchInSec_max']
            state.effectsConfig.chorus.bounds.voices.min = effectsConfig['nc_chorus_voices_min']
            state.effectsConfig.chorus.bounds.voices.max = effectsConfig['nc_chorus_voices_max']
            state.effectsConfig.chorus.bounds.rateInHz.min = effectsConfig['nc_chorus_rateInHz_min']
            state.effectsConfig.chorus.bounds.rateInHz.max = effectsConfig['nc_chorus_rateInHz_max']
            state.effectsConfig.chorus.bounds.depth.min = effectsConfig['nc_chorus_depth_min']
            state.effectsConfig.chorus.bounds.depth.max = effectsConfig['nc_chorus_depth_max']
            state.effectsConfig.chorus.bounds.width.min = effectsConfig['nc_chorus_width_min']
            state.effectsConfig.chorus.bounds.width.max = effectsConfig['nc_chorus_width_max']
            state.effectsConfig.chorus.bounds.mixer.min = effectsConfig['nc_chorus_mixer_min']
            state.effectsConfig.chorus.bounds.mixer.max = effectsConfig['nc_chorus_mixer_max']
        }
    },
    message(state, res) {
        if (state.messages.length > 100) {
            state.messages.splice(0, state.messages.length - 100)
        }
        state.messages.push({
            timestamp: new Date(),
            code: res.message,
            isError: res.isError
        })
        if (res.isError) {
            state.hasError = true
            console.log('NALS error: ' + res.message)
        }
        if (res.message === 'AUDIO_DRIVER_NOT_STARTED') {
            state.hasAudioDriverError = true
        }
    },
    clearMessages(state) {
        state.messages = []
    },
    toggleAutoJitter(state, value) {
        state.currentConfig.jitterToggle = value
    },
    setLocalJitter(state, value) {
        state.currentConfig.jitterConfig.local = value
        NALS.setJitter(value, state.currentConfig.jitterConfig.server)
    },
    setServerJitter(state, value) {
        state.currentConfig.jitterConfig.server = value
        NALS.setJitter(state.currentConfig.jitterConfig.local, value)
    },
    setSoundCard(state, value) {
        state.currentConfig.soundCard = value
    },
    setAudioChannel(state, value) {
        state.currentConfig.audioChannel = value
    },
    setAudioQuality(state, value) {
        state.currentConfig.audioQuality = value
    },
    setReverb(state, value) {
        state.currentConfig.reverbValue = value
    },
    setAmpEffect(state, value) {
        state.effectsConfig.amp.enabled = value
    },
    setAmpSelectedStack(state, value) {
        state.effectsConfig.amp.selectedStack = value
    },
    setAmpInsane(state, value) {
        state.effectsConfig.amp.insane = value
    },
    setAmpBass(state, value) {
        state.effectsConfig.amp.bass = value
    },
    setAmpMiddle(state, value) {
        state.effectsConfig.amp.middle = value
    },
    setAmpTreble(state, value) {
        state.effectsConfig.amp.treble = value
    },
    setAmpDrive(state, value) {
        state.effectsConfig.amp.drive = value
    },
    setAmpGain(state, value) {
        state.effectsConfig.amp.gain = value
    },
    setDelayEffect(state, value) {
        state.effectsConfig.delay.enabled = value
    },
    setDelayTimeInSec(state, value) {
        state.effectsConfig.delay.timeInSec = value
    },
    setDelayFeedback(state, value) {
        state.effectsConfig.delay.feedback = value
    },
    setDelayMixer(state, value) {
        state.effectsConfig.delay.mixer = value
    },
    setDelayChorus(state, value) {
        state.effectsConfig.delay.chorus = value
    },
    setChorusEffect(state, value) {
        state.effectsConfig.chorus.enabled = value
    },
    setChorusPitch(state, value) {
        state.effectsConfig.chorus.pitchInSec = value
    },
    setChorusVoices(state, value) {
        state.effectsConfig.chorus.voices = value
    },
    setChorusRate(state, value) {
        state.effectsConfig.chorus.rateInHz = value
    },
    setChorusDepth(state, value) {
        state.effectsConfig.chorus.depth = value
    },
    setChorusWidth(state, value) {
        state.effectsConfig.chorus.width = value
    },
    setChorusMixer(state, value) {
        state.effectsConfig.chorus.mixer = value
    },
    setAudioBufferSize(state, value) {
        state.currentConfig.audioBufferSize = value
    }
}

export const getters = {
    hasNativeEffects(state) {
        return state.version !== '' && semver.gte(state.version, '0.10.0')
    },
    hasChorusEffect(state) {
        return state.version !== '' && semver.gte(state.version, '0.10.2')
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}