<template>
  <div>
    <h1>{{ $t("not-found.page-title") }}</h1>

    <p class="text-center">
      {{ $t('not-found.this-page-does-not-exist') }} <br/><br/>
      <router-link :to="{ name: 'hall' }" class="button primary">
        {{ $t('not-found.back-to-hall') }}
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
  metaInfo() {
    return {title: this.$t('not-found.page-title')}
  }
}
</script>
