export default {
    lockInstall(state, isLocked) {
        state.installLock = isLocked
    },
    saveConfig(state, config) {
        state.config = config
    },
    openModal(state) {
        state.modalIsOpen = true
    },
    closeModal(state) {
        state.modalIsOpen = false
    },
    toggleMenu(state) {
        state.menuIsOpen = !state.menuIsOpen
    },
    showAppStart(state) {
        state.appStartViewed = true
    },
    setHideFooter(state, hide) {
        state.hideFooter = hide
    }
}