<template>
  <ul class="dropdown menu" v-click-outside="closeMenu">
    <li class="is-dropdown-submenu-parent"
        :class="{'opens-right': !openLeft, 'opens-left': openLeft}"
        tabindex="0"
        @keyup.esc="closeMenu"
        @keyup.enter.space="toggleMenu"
        @click="toggleMenu">
      <button class="dropdown-title" tabindex="-1" :aria-expanded="isOpen" @click.prevent="() => {}">
        <slot name="title"></slot>
      </button>
      <ul class="menu submenu is-dropdown-submenu first-sub vertical" :class="{'js-dropdown-active': isOpen}">
        <slot></slot>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    openLeft: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    closeMenu() {
      this.isOpen = false
    }
  }
}
</script>

<style scoped lang="css">
.dropdown-title {
  color: #eee;
}

.dropdown-title:hover {
  color: #aaa;
  cursor: pointer;
}

.dropdown-title:hover i {
  color: #eee;
}

.menu {
  background-color: #010415;
  color: #eee;
  vertical-align: middle;
}
</style>