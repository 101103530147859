<template>
  <header class="layout-header" id="header">
    <nav :aria-label="$t('menu.main-menu')" class="top-bar">
      <layout-header-logo/>
      <menu-toggle-button @toggle-menu="onToggleMenu"/>
      <layout-guest-speedbar
        v-if="currentUser === null"/>
      <layout-speedbar
        :nals-error="nalsError"
        v-else/>
    </nav>
  </header>
</template>

<script>
import LayoutHeaderLogo from '../../components/layout/LayoutHeaderLogo.vue'
import LayoutSpeedbar from '../../components/layout/LayoutSpeedbar.vue'
import LayoutGuestSpeedbar from '../../components/layout/LayoutGuestSpeedbar.vue'
import MenuToggleButton from '../../components/layout/MenuToggleButton.vue'

export default {
  name: 'LayoutHeader',
  components: {
    MenuToggleButton,
    LayoutGuestSpeedbar,
    LayoutSpeedbar,
    LayoutHeaderLogo
  },
  data: function () {
    return {
      nalsError: ''
    }
  },
  mounted() {
    this.bindEvents()
  },
  beforeDestroy() {
    this.unbindEvents()
  },
  methods: {
    bindEvents: function () {
      document.addEventListener('nals_error_received', this.onErrorReceived)
    },
    unbindEvents: function () {
      document.removeEventListener('nals_error_received', this.onErrorReceived)
    },
    onErrorReceived: function (e) {
      this.nalsError = e.detail.code
    },
    onToggleMenu: function () {
      this.$store.commit('toggleMenu')
    }
  }
}
</script>

<style lang="css" scoped>
.top-bar {
  background-color: #010415;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 39.9988em) {
  .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.layout-header {
  background-color: #18181b;
}
</style>
