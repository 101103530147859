import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import nals from './modules/nals'
import rehearsalSession from '../modules/rehearsalsession/store'
import user from './modules/user'
import privatemessage from '../modules/privatemessage/store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        config: {},
        modalIsOpen: false,
        menuIsOpen: false,
        appStartViewed: false,
        hideFooter: false,
        installLock: false
    },
    mutations,
    actions,
    modules: {
        nals,
        rehearsalSession,
        user,
        privatemessage
    }
})
