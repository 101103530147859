/**
 * TODO this is attached to the note icon in the menubar
 */
import NALS from '../lib/nals-core'
import { protocolCheck } from './custom-protocol-check'

export function checkAndStartNals(queueHost, nalsToken) {
    if (NALS.msgReceived === true) {
        // Nothing to do NALS is running already
    } else {
        // start NALS launcher. For debug mode user must use the audio settings page (and if no update available)
        const url = btoa('broker=' + queueHost + '&token=' + nalsToken)

        protocolCheck(
            'nucorder:' + url,
            () => {
                document.dispatchEvent(new CustomEvent('nalsl_not_installed', {}))
            },
            () => {
                NALS.tryConnectNalsClient(nalsToken)
            }
        )
    }
}
