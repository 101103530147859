export function checkDefaultLanguage () {
  let matched = null
  const languages = ['fr', 'en']
  const navigatorLanguage = navigator.language || navigator.userLanguage
  languages.forEach(lang => {
    if (lang === navigatorLanguage) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigatorLanguage.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigatorLanguage.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    // Default if nothing matched
    matched = 'en'
  }
  return matched
}
