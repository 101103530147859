<template>
  <modal-dialog :modal-title="$t('tutorial-modal.title')">
    <template>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/J-7G0gQqfLU" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'

export default {
  name: 'TutorialModal',
  components: {ModalDialog}
}
</script>
