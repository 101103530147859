/**
 * NALS interaction tools
 *
 * Created by Stanislas Michalak on 03/03/17.
 *
 * This script works along with the title bar into userlayout struts tag. It's role is to handle NALSL/NALS
 * dialog. NALSL/NALS dialog concerns
 * - MQTT connection to NALSL
 * - NALS/NALS dialog status
 * - start/stop, upgrade command dedicated to NALSL, and
 * - audio commands (connect to room, audio settings, real time setting) dedicated to NALS
 *
 * The NALS object handle dialogs, that is a set of order messages sent through NALS and a handler
 * to every sent back messages from NALSL.
 *
 * In addition, (fix 2607) this script is responsible for handling NALS connection to room status on page
 * leave. That is: if NALS is known gto be connected to a room and change of page is detected, this script
 * is responsible for sending a disconnect order to NALS.
 *
 * Upper layers running rooms for players must use the NALS object for any audio operation and leave status
 * management to here. This means that the only listeners upper layers scripts are supposed to use concerns
 * audio connection and audio setting settings.
 *
 * @author jnh
 *
 */

import Paho from 'paho-mqtt'
import {track} from './tracking'
import semver from 'semver'

/**
 * The communication object
 */
const NALS =
    {
        debug: false,
        nalsClientId: -1,
        nalsToken: null,
        version: '', // Will hold the NALS version (-not NALS Launcher)
        deviceName: '',
        devicePort: -1,
        mqttConnection: null,
        mqttConnected: false, // Dialog to NALSL through MQTT stuff is connected
        updatesEventsSource: null,
        msgReceived: false, // Message are received from NALS/NALS
        roomConnected: false, // We have sent connectToRoom order, NALS is considered as connected
        nalsHost: '', // Operating system name as found by NALS

        // #3954 add something detecting poorConnection on 100 points
        pingTimeSize: 30, // "room_qos" event will ring every 30 s
        pingTimeTable: [],
        pingTimeIndex: 0,
        pingTimeFilled: false,

        /**
         * public
         * Ask NALSL to download a new version of NALS
         */
        downloadNals: function (version) {
            this.sendMessage('nals_download',
                {
                    version: version
                }
            )
            track(['nals', 'download'])
        },

        /**
         * public
         * Ask NALSL to upgrade to new (already downloaded) version of NALS
         */
        upgradeNals: function () {
            this.sendMessage('nals_upgrade', {})
            track(['nals', 'upgrade'])
        },

        /**
         * public
         * Ask NALSL to downgrade to previous (assumed to work) version of NALS
         */
        rollbackNals: function () {
            this.sendMessage('nals_rollback', {})
            track(['nals', 'rollback'])
        },

        /**
         * Start NALS
         */
        startNals: function () {
            this.sendMessage('nals_start', {})
        },

        /**
         * Ask NALS to disconnect from a room
         */
        disconnectFromRoom: function () {
            this.sendMessage('disconnect', {})
            this.roomConnected = false
        },

        /**
         * public
         * Select the audio device used by NALS
         */
        setSoundCard: function (cardName) {
            this.sendMessage('set_sound_card',
                {
                    soundCard: cardName
                }
            )
        },

        /**
         * public
         * Open the device UI page (Windows only)
         */
        openDriverConfig: function () {
            this.sendMessage('open_driver_config', {})
        },

        /**
         * public
         * Ask NALS to connect to a Room
         * @param roomIp audio server address
         * @param roomPort audio server port
         * @param playerId
         * @param testModeCharge
         */
        connectToRoom: function (roomIp, roomPort, playerId, testModeCharge) {
            console.log('NALS try to connect ' + playerId + ' to ' + roomIp + '/' + roomPort)

            // We need to reset these counters to get accurate values for the current room,
            // otherwise we'll have some points from the previous room.
            this.resetPingCounters()

            if (testModeCharge === undefined) {
                this.sendMessage('connect',
                    {
                        room:
                            {
                                ip: roomIp,
                                port: parseInt(roomPort, 10), // fix #3213 encode as int otherwise NALS 0.5.6 do not understand JSON
                                player: playerId.toString() // fix 3036 : added player name has needed by NALS
                                //            before NALS 0.5.6 this additional field will be ignored
                            }
                    }
                )
            } else {
                this.sendMessage('connect',
                    {
                        room:
                            {
                                ip: roomIp,
                                port: parseInt(roomPort, 10), // fix #3213
                                player: playerId.toString(), // fix 3036 : added player name as needed by NALS
                                charge: parseInt(testModeCharge, 10)
                            }
                    }
                )
            }

            this.roomConnected = true // Consider as connected
        },

        /**
         * public
         * Get ping to the given server, without connecting to it
         * @param roomIp
         * @param roomPort
         */
        ping: function (roomIp, roomPort) {
            if (this.version !== '' && semver.gte(this.version, '0.9.0')) {
                this.sendMessage('ping',
                    {
                        room:
                            {
                                ip: roomIp,
                                port: parseInt(roomPort, 10)
                            }
                    }
                )
            }
        },

        resetPingCounters: function () {
            this.pingTimeTable = []
            this.pingTimeTableVariance = []
            this.pingTimeIndex = 0
            this.pingTimeSumVariance = 0
            this.pingTimeFilled = false

            for (let i = 0; i < this.pingTimeSize; i++) {
                this.pingTimeTable.push(0)
                this.pingTimeTableVariance.push(0)
            }
        },

        /**
         * public
         * Set audio channel mono or stereo
         */
        setAudioChannel: function (audioChannel) {
            this.sendMessage('set_audio_channel',
                {
                    channel: audioChannel // value can be 0 = mono, 1 = stereo, 2 = mono in / stereo out
                }
            )
        },

        /**
         * public
         * Set audio channel quality
         */
        setAudioQuality: function (audioQuality) {
            this.sendMessage('set_audio_quality',
                {
                    quality: audioQuality // value can be either 0 = low, 1 = normal or 2 = high
                }
            )
        },

        /**
         * public
         * Set OPUS protocol real time buffer size
         */
        setBuffer: function (bufferTime) {
            this.sendMessage('set_buffer',
                {
                    time: parseInt(bufferTime, 10) // value is in milliseconds (TODO Not true)
                }
            )
        },

        /**
         * public
         * Set on/off realtime buffer auto configuration
         */
        toggleAutoJitter: function () {
            this.sendMessage('toggle_auto_jitter', {})
        },

        /**
         * public
         * Set buffer jitter for local and server
         *
         */
        setJitter: function (jitterLocal, jitterServer) {
            if (!Number.isInteger(jitterLocal)) {
                jitterLocal = parseInt(jitterLocal, 10)
            }
            if (!Number.isInteger(jitterServer)) {
                jitterServer = parseInt(jitterServer, 10)
            }
            this.sendMessage('set_jitter',
                {
                    local: jitterLocal,
                    server: jitterServer
                }
            )
        },

        /**
         * public
         * Set local volume
         * TODO to be checked (not sure you can set separates)
         */
        setSlotVolume: function (slotId, volume) {
            this.sendMessage('set_slot_volume',
                {
                    slotId: slotId,
                    volume: parseInt(volume, 10)
                }
            )
        },

        /**
         * public
         * Mute one player
         */
        setSlotMute: function (slotId, mute) {
            this.sendMessage('set_slot_mute',
                {
                    slotId: slotId,
                    mute: mute
                }
            )
        },

        /**
         * public
         * Set solo one payer
         */
        setSlotSolo: function (slotId, solo) {
            this.sendMessage('set_slot_solo',
                {
                    slotId: slotId,
                    solo: solo
                }
            )
        },

        /**
         * public
         * Get all device list from OS
         */
        getSoundCardList: function () {
            this.sendMessage('get_sound_card_list', {})
        },

        /**
         * public
         * Ask NALS to send config/status
         */
        getCurrentConfig: function () {
            this.sendMessage('get_current_config', {})
        },

        /**
         * public
         * Set L/R direction for local slot only
         */
        setDirection: function (directionValue) {
            this.sendMessage('set_direction',
                {
                    direction: parseInt(directionValue, 10)
                }
            )
        },

        /**
         * public
         * Set reverb for local slot only
         */
        setReverb: function (reverbValue) {
            this.sendMessage('set_reverb',
                {
                    level: reverbValue
                }
            )
        },

        /**
         * public
         * Ask NALS to start record
         */
        startRecordToFile: function () {
            this.sendMessage('start_record_to_file',
                {
                    max_duration_in_sec: 600
                }
            )
        },

        /**
         * public
         * Ask NALS to stop record
         */
        stopRecord: function () {
            this.sendMessage('stop_record',
                {}
            )
        },

        /**
         * public
         * Ask NALS to stop
         */
        stop: function () {
            this.sendMessage('stop',
                {
                    delay: 100
                }
            )
        },

        /**
         * public
         * List local NALS records.
         */
        listLocalRecords: function () {
            if (this.version !== '' && semver.gte(this.version, '0.6.4')) {
                this.sendMessage('list', {})
            }
        },

        /**
         * public
         * Delete local NALS record using its name.
         */
        deleteLocalRecord: function (recordName) {
            if (this.version !== '' && semver.gte(this.version, '0.6.4')) {
                this.sendMessage('delete', {
                    fullName: recordName
                })
            }
        },

        /**
         * Get a list of NALS native effects
         */
        getNativeEffectsInfo: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native_info', {})
            }
        },

        /**
         * Enable distortion effect
         */
        enableDistortion: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    distorsion: {
                        state: true
                    }
                })
            }
        },

        setDistortionTimbre: function (timbre) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    distorsion: {
                        timbre
                    }
                })
            }
        },

        setDistortionDepth: function (depth) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    distorsion: {
                        depth
                    }
                })
            }
        },

        /**
         * Disable distortion effect
         */
        disableDistortion: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    distorsion: {
                        state: false
                    }
                })
            }
        },

        /**
         * Enable delay effect
         */
        enableDelay: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        state: true
                    }
                })
            }
        },

        setDelayTimeInSec: function (timeInSec) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        'time_in_sec': timeInSec
                    }
                })
            }
        },

        setDelayFeedback: function (feedback) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        feedback
                    }
                })
            }
        },

        setDelayMixer: function (mixer) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        mixer
                    }
                })
            }
        },


        setDelayChorus: function (chorus) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        'chorus_on': chorus
                    }
                })
            }
        },

        /**
         * Disable delay effect
         */
        disableDelay: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    delay: {
                        state: false
                    }
                })
            }
        },

        enableZtube: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        state: true,
                    }
                })
            }
        },

        setZtubeStack: function (stackIndex) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        stack: stackIndex,
                    }
                })
            }
        },

        setZtubeBass: function (bass) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        bass,
                    }
                })
            }
        },

        setZtubeMiddle: function (middle) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        middle,
                    }
                })
            }
        },

        setZtubeTreble: function (treble) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        treble,
                    }
                })
            }
        },

        setZtubeDrive: function (drive) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        drive,
                    }
                })
            }
        },

        setZtubeGain: function (gain) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        gain,
                    }
                })
            }
        },

        setZtubeInsane: function (insane) {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        insane
                    }
                })
            }
        },

        /**
         * Disable ztube effect
         */
        disableZtube: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.0')) {
                this.sendMessage('native', {
                    ztube: {
                        state: false
                    }
                })
            }
        },

        /**
         * Enable chorus effect
         */
        enableChorus: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        state: true
                    }
                })
            }
        },

        setChorusPitch: function (pitchInSec) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        pitch_in_sec: pitchInSec
                    }
                })
            }
        },

        setChorusVoices: function (voices) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        voices
                    }
                })
            }
        },

        setChorusRate: function (rateInHz) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        rate_in_hz: rateInHz,
                    }
                })
            }
        },

        setChorusDepth: function (depth) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        depth,
                    }
                })
            }
        },

        setChorusMixer: function (mixer) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        mixer,
                    }
                })
            }
        },

        setChorusWidth: function (width) {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        width,
                    }
                })
            }
        },

        /**
         * Disable chorus effect
         */
        disableChorus: function () {
            if (this.version !== '' && semver.gte(this.version, '0.10.2')) {
                this.sendMessage('native', {
                    chorus: {
                        state: false
                    }
                })
            }
        },

        /**
         * Set the preferred audio frame size
         * @param size The preferred audio frame size (either 64, 128 or 256)
         */
        setPrefAudioBufferSize: function (size) {
            if (this.version !== '' && semver.gte(this.version, '0.11.0')) {
                this.sendMessage('set_pref_audio_frame_size', {
                    'frame_size': size
                })
            }
        },

        /**
         * internal
         * handleMessage transforms a MQTT incoming message into a javascript event (for uper layer scripts)
         * @param e Websocket event
         */
        handleMessage: function (e) {
            const data = JSON.parse(e.data)
            let eventDetail = {}
            let eventName = ''

            if (Object.prototype.hasOwnProperty.call(data, 'signal')) {
                const signalName = data.signal
                let signalData = data.data

                switch (signalName) {
                    // Signal
                    // retrieves the device list
                    case 'sound_card_list':
                        eventName = 'sound_card_list_received'
                        eventDetail.soundCardList = signalData
                        this.msgReceived = true
                        break

                    // Signal
                    // retrieves the status and configuration
                    case 'current_config':
                        eventName = 'current_config_received'
                        eventDetail = signalData
                        this.msgReceived = true
                        break

                    // Signal
                    // retrieves the ping time
                    case 'ping':
                        eventName = 'ping_received'
                        eventDetail.ping = signalData.ping
                        eventDetail.overallDelay = signalData.overallDelay
                        this.msgReceived = true // This will case a custom event ping to be signaled

                        window.setTimeout(this.computePing(signalData.ping), 1)
                        break
                    // Signal
                    // retrieves the connection-less ping time
                    case 'cl_ping':
                        eventName = 'cl_ping_received'
                        eventDetail.ping = signalData.ping
                        eventDetail.overallDelay = signalData.overallDelay
                        eventDetail.room = signalData.room
                        this.msgReceived = true // This will case a custom event ping to be signaled
                        break
                    // Signal
                    // retrieves a chat message from the connected room
                    case 'private_chat_message':
                        eventName = 'private_chat_message_received'
                        eventDetail.message = signalData
                        this.msgReceived = true
                        break

                    // Signal
                    // retrieves a error message
                    case 'error':
                        eventName = 'nals_error_received'
                        eventDetail.code = signalData
                        console.log('NALS returned error: ' + signalData)
                        this.msgReceived = true
                        break

                    // Signal
                    // retrieves a status message in response to get_config command
                    case 'status':
                        eventName = 'status_received'
                        eventDetail.status = signalData
                        this.msgReceived = true
                        break

                    // Signal
                    // retrieves a version message in response to hello command
                    case 'hello':
                        eventName = 'hello_received'
                        this.deviceName = signalData.hostname
                        this.version = signalData.version
                        eventDetail.nals_version = signalData.version
                        eventDetail.host = signalData.host
                        this.nalsHost = signalData.host // Keep a copy
                        eventDetail.name = signalData.name ? signalData.name : 'NALS'
                        eventDetail.hostname = signalData.hostname
                        eventDetail.isDebug = signalData.isDebug
                        this.msgReceived = true
                        break

                    // Signal
                    // received when NALSL quit (external desktop menu)
                    case 'bye':
                        eventName = 'bye_received'
                        // bye is the signal sent by NALS on quit. This means we need
                        // to clean the previous NALS slot, in case a new NALS instance
                        // starts and claim the spot
                        this.msgReceived = false
                        break

                    // Signal
                    // received when NALSL has downloaded a new NALS version
                    case 'nals_upgrade_ready':
                        eventName = 'nals_upgrade_ready_received'
                        console.log('NALS upgrade is ready!')
                        this.msgReceived = true
                        break

                    // Signal
                    // received audio levels in response to t set audio command
                    case 'audio_levels':
                        eventName = 'audio_levels_received'
                        // Since 0.5.0, audio_levels data is a single object
                        if (!Array.isArray(signalData)) {
                            signalData = [signalData]
                        }
                        eventDetail.audioLevels = signalData
                        this.msgReceived = true
                        break

                    // Signal
                    // received when NALS received a player connection (including self in response
                    // to connectToRoom)
                    case 'cnx':
                        eventName = 'player_connection_received'
                        eventDetail.slotId = signalData.channelId
                        eventDetail.playerName = signalData.name
                        this.msgReceived = true
                        break

                    // Signal
                    // received when NALS received a player disconnection (including self in response
                    // to disconnectFromRoom)
                    case 'dis':
                        eventName = 'player_disconnection_received'
                        eventDetail.slotId = signalData.channelId
                        this.msgReceived = true
                        break

                    case 'recording':
                        eventName = 'recording'
                        eventDetail.fileName = signalData['file name']
                        this.msgReceived = true
                        break

                    case 'not_recording':
                        eventName = 'not_recording'
                        this.msgReceived = true
                        break

                    case 'catalog':
                        eventName = 'records_catalog'
                        eventDetail.catalog = signalData
                        this.msgReceived = true
                        break

                    case 'native_info':
                        eventName = 'native_effects_info'
                        eventDetail = signalData
                        break

                    default:
                        // Event not known
                        // Dispatch raw event as "broker_message_received"
                        document.dispatchEvent(new CustomEvent('broker_message_received',
                            {
                                detail: data
                            }
                        ))
                        return
                }
            } else {
                // Event not known
                // Dispatch raw event as "broker_message_received"
                document.dispatchEvent(new CustomEvent('broker_message_received',
                    {
                        detail: data
                    }
                ))
                return
            }

            const event = new CustomEvent(eventName, {
                detail: eventDetail
            })
            // Dispatch parsed event
            document.dispatchEvent(event)
        },

        /**
         * internal
         * Initialization:  prepare the MQTT connection object for talking to NALSL (and NALS)
         */
        initBroker: function (userLogin, brokerHost, nalsToken) {
            const brokerPort = location.protocol === 'https:' ? 8084 : 8083 // broker port
            const brokerClient = new Paho.Client(
                brokerHost,
                Number(brokerPort),
                userLogin + '_' + Math.ceil(Math.random() * 100)
            )

            brokerClient.onConnectionLost = (responseObject) => {
                if (responseObject.errorCode !== 0) {
                    if (responseObject.errorCode !== 7) {
                        // Handle error now
                        console.log('Broker connection lost - ' + responseObject.errorMessage)
                    } else {
                        // Fix connection timeout glitch
                        console.log('Broker connection timeout')
                    }
                }
                this.mqttConnected = false
                this.msgReceived = false
            }
            brokerClient.onMessageArrived = (message) => {
                this.handleMessage({data: message.payloadString})
            }
            this.mqttConnection = brokerClient

            // Try to connect once
            this.tryConnectNalsClient(nalsToken)
        },

        initDialog: function () {
            this.hello() // Need hello to get the current version
        },

        /**
         * internal
         * tryConnectNalsClient : Try to connect to the NALS client
         */
        tryConnectNalsClient: function (nalsToken) {
            this.nalsToken = nalsToken
            const options = {
                timeout: 3,
                reconnect: true,
                keepAliveInterval: 300, // Keep WS connection active for 5min without activity

                onSuccess: () => {
                    this.mqttConnected = true
                    console.log('Broker connected')
                    this.mqttConnection.subscribe('/topic/nals/' + nalsToken + '/out', {qos: 1})
                    document.dispatchEvent(new CustomEvent('broker_connected', {}))
                    this.initDialog()
                },

                onFailure: (message) => {
                    console.error('Broker connection failure - ' + message.errorMessage)
                }
            }

            if (location.protocol === 'https:') {
                options.useSSL = true
            }
            this.mqttConnection.connect(options)
        },

        /**
         * internal
         * Send a message through MQTT to NALSL
         */
        sendMessage: function (action, data) {
            const wsData = {
                action: action,
                data: data
            }
            // console.log(wsData)
            if (this.mqttConnected) {
                // MQTT message
                const message = new Paho.Message(JSON.stringify(wsData))
                message.destinationName = '/topic/nals/' + this.nalsToken + '/in'
                this.mqttConnection.send(message)
            } else {
                console.log('Unable to do "' + action + '": not connected to NALS')
            }
        },

        /**
         * internal
         * Ask NALSL to identify
         */
        hello: function () {
            this.sendMessage('hello', {})
        },
        computePing: function (ping) {
            // #3954
            // calculate the average ping time over this.pingTimeSize points
            this.pingTimeTable[this.pingTimeIndex] = ping // Store new value (for last known)
            this.pingTimeIndex++

            // Rotate circular buffer
            if (this.pingTimeIndex >= this.pingTimeSize) {
                this.pingTimeFilled = true
                this.pingTimeIndex = 0
            }

            // Now if the table is filled, calculates figures (average, sigma, min and max)
            if (this.pingTimeFilled) {
                // Ring room_qos once per buffer loop
                if (this.pingTimeIndex > 0) {
                    return
                }
                // Copy current buffer to prevent override when computing stats
                const pingTable = Array.from(this.pingTimeTable)

                const pingSum = pingTable.reduce((a, b) => a + b, 0)
                const averagePingTime = pingSum / this.pingTimeSize

                // Add sigma calculation
                const s = pingTable
                    .map(a => Math.pow(a - averagePingTime, 2))
                    .reduce((a, b) => a + b, 0)
                const sigmaPingTime = Math.sqrt(s / (this.pingTimeSize - 1))

                // Add min max calculation over moving window
                const min = Math.min(...pingTable)
                const max = Math.max(...pingTable)
                const qosEvent = new CustomEvent('room_qos', {
                    detail: {
                        minPingInMs: min,
                        maxPingInMs: max,
                        averagePingInMs: averagePingTime,
                        sigmaPingInMs: sigmaPingTime
                    }
                })

                // Dispatch parsed event
                document.dispatchEvent(qosEvent)
            }
        }
    } // End of NALS object

export default NALS
