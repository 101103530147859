<template>
  <span class="title">
    <slot />
    <font-awesome-icon icon="fa-solid fa-caret-down" />
  </span>
</template>

<script>
export default {
  name: "DropdownMenuTitle",
}
</script>

<style scoped>
.title > .fa-caret-down {
  margin-left: 0.3rem;
}
</style>